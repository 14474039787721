import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';

class Background extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const mainContent = (
            <React.Fragment>
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-5xl">Latar Belakang</h1>
                </div>

                <div className="p-10">
                    <p className="px-5 lg:px-10 text-justify">
                        Bencana geologi khususnya tanah runtuh dikenal pasti menjadi suatu ancaman dan memberi impak yang serius kepada sosio-ekonomi negara. Ia boleh mengakibatkan kehilangan nyawa, kerosakan infrastruktur dan kemusnahan harta benda serta memberi kesan ketidakselesaan kepada komuniti yang terlibat. Beban kewangan yang perlu ditanggung oleh kerajaan adalah menjurus kepada kos yang tinggi untuk kerja-kerja menyelamat, pembaikan dan pembinaan semula (reconstruction).
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        Pada 18 Ogos 2021, negara telah dikejutkan dengan kejadian bencana geologi aliran puing di Gunung Jerai yang turut meragut enam (6) nyawa, merosakkan harta benda dan infrastruktur yang dilaporkan oleh media yang menyebabkan kerugian dianggarkan sebanyak RM75 juta. Sepanjang tempoh Monsun Timur Laut 2021/2022 pula telah menyaksikan kejadian tanah runtuh di seluruh negara yang telah memberi kesan kemusnahan signifikan serta gangguan kepada aktiviti sosial dan ekonomi.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        Selain daripada faktor alam semula jadi, tanah runtuh juga boleh berlaku akibat daripada sikap dan kecuaian manusia. Pelaksanaan pembangunan guna tanah yang kurang terancang, pembangunan di kawasan cerun dan kawasan tadahan hujan, pembalakan yang tidak terkawal serta pembakaran terbuka boleh mengundang kejadian tanah runtuh.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        Sehubungan dengan itu,  penyediaan Sistem Pemantauan dan Amaran Awal Bencana Geologi ini adalah untuk menjalankan pemantauan ketidakstabilan cerun di kawasan tumpuan pengunjung iaitu Taman Eko Rimba (TER) Chemerong, Lata Payung dan Air Menderu, Terengganu. Ia dilaksanakan untuk mengurangkan risiko kehilangan nyawa dan kerosakan harta benda.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        Sistem ini berfungsi dengan menghimpunkan maklumat hujan secara real-time daripada tiga stesen tolok hujan di Taman Eko Rimba (TER) Chemerong, Lata Payung dan Air Menderu, Terengganu. Data hujan ini di kutip daripada setiap stesen cerapan dan di hantar ke Pangkalan Data Telemetri. Sistem ini beroperasi secara dalaman iaitu maklumat hujan akan dipantau oleh pegawai-pegawai JMG sahaja.

                    </p>

                    <br />
                    <div className="divider"></div> 
                    <br />

                    <p className="px-5 lg:px-10 text-3xl font-bold">
                        OBJEKTIF PROJEK
                    </p>

                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        (a)	Menyediakan maklumat bahaya dan risiko cerun di kawasan terpilih untuk membantu pihak berkuasa tempatan dalam pengurusan cerun dan bencana geologi tanah runtuh.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        (b)	Menyediakan cadangan konsep mitigasi dan amaran awal bencana geologi tanah runtuh dalam meningkatkan kesiapsiagaan komuniti.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        (c)	Memperkukuhkan infrastruktur Sistem Maklumat Geospatial Terain dan Cerun Negara (NaTSIS) bagi meningkatkan capaian dan perkongsian data raya serta maklumat.
                    </p>
                    <p className="px-5 lg:px-10 pt-5 text-justify">
                        (d)	Membantu agensi kerajaan dan pihak berkuasa tempatan dalam kawalan perancangan pembangunan dan guna tanah lestari.
                    </p>
                </div>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />
                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(Background);