import React from 'react';
import COAImage from '../static/Coat_of_arms_of_Malaysia.svg.png';
import JPSMLogo from '../static/logo-jpsm1.png';
import Logo from '../static/JMG Logo.png';
import SpecConnectLogo from '../static/Specconnect logo.png';

class NavSideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { pageContent } = this.props
        return (
            <React.Fragment>
                <div className="navbar bg-base-100 justify-center flex">
                    {/* <div className="flex absolute left-5">
                        <img className="object-contain h-16 w-32" src={SpecConnectLogo} alt="#" />
                    </div> */}
                    <img className="object-contain h-8 w-16 md:h-16 md:w-60 md:left-5 md:absolute pl-5" src={SpecConnectLogo} alt="#" />
                    <img className="object-contain h-8 w-16 md:h-16 md:w-32" src={Logo} alt="#" />
                    <img className="object-contain h-8 w-16 md:h-16 md:w-32" src={COAImage} alt="#" />
                    <img className="object-contain h-8 w-16 md:h-16 md:w-32" src={JPSMLogo} alt="#" />
                </div>
                <div className="navbar bg-base-100 border border-gray-300 w-screen justify-center text-center flex">

                    <div className="flex-none">
                        <label htmlFor="my-drawer-2" className="btn btn-square btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                        </label>
                    </div>

                    <div className="flex-wrap justify-center pt-2 md:pl-8 lg:pl-48 xl:pl-72">
                        <a href="#!" class="mb-4 text-xl font-extrabold text-gray-900 md:text-xl lg:text-2xl">
                            <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-500 from-sky-400">
                                SISTEM PEMANTAUAN </span>
                        </a>

                        <a href="#!" class="mb-4 text-xl font-extrabold text-gray-900 md:text-xl lg:text-2xl">
                            &nbsp;DAN
                        </a>
                        <a href="#!" class="mb-4 text-xl font-extrabold text-gray-900 md:text-xl lg:text-2xl">
                            <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-500 from-sky-400">
                                &nbsp;AMARAN AWAL</span>
                        </a>
                        <a href="#!" class="mb-4 text-xl font-extrabold text-gray-900 md:text-xl lg:text-2xl">
                            &nbsp;BENCANA GEOLOGI
                        </a>
                    </div>

                    <div className="navbar-end w-2/12">
                        <div className="flex absolute right-5">
                            <div className="dropdown dropdown-end">
                                <button tabIndex={0} className="btn btn-square btn-ghost">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
                                </button>
                                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 border border-gray-300">
                                    <li><a href="/">Log Keluar</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="drawer lg:drawer-open ">
                    <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content flex flex-col z-0">
                        {/* Page content here */}
                        {pageContent}
                    </div>
                    <div className="drawer-side">
                        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-80 bg-base-200 text-base-content border border-gray-200 z-10 min-h-screen">
                            {/* Sidebar content here */}
                            <li className="menu-title text-xl text-black">Sumber</li>
                            <li><a href="/Background" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                Latar Belakang
                            </a></li>
                            {localStorage.getItem("user_tag") === "jmg_n9" ?
                                null
                                :
                                <li><a href="/Organization" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                    Struktur Organisasi
                                </a></li>
                            }

                            <li><a href="/Contact" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                Hubungi Kami
                            </a></li>


                            <div className="divider"></div>
                            <li className="menu-title text-xl text-black">Data</li>
                            <li><a href="/Home" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="10" r="3" /><path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" /></svg>
                                Pemantauan Data Hujan
                            </a></li>
                            <li><a href="/Data" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18" /></svg>
                                Data Hujan
                            </a></li>
                            <li><a href="/Charts" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 3v18h18" /><path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" /></svg>
                                Graf Data Plot
                            </a></li>
                            <div className="divider"></div>
                            <li className="menu-title text-xl text-black">Pengurusan Stesen</li>
                            <li><a href="/StationData" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>
                                Senarai Stesen
                            </a></li>
                            <li><a href="/SirenLog" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
                                Senarai Log Siren
                            </a></li>
                            <div className="divider"></div>
                            <li className="menu-title text-xl text-black">Pengurusan Pengguna</li>
                            <li><a href="/UserData" className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                Senarai Pengguna
                            </a></li>
                            <div className="divider"></div>
                        </ul>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

export default NavSideBar;