import React from 'react';
import Routes from './Router/Routes';

class App extends React.Component {
  render() {
    return (
      <Routes />
    )
  }
}

export default App;
