import { useNavigate } from "react-router-dom";

export const withNavigate = (Component) => {
    const Wrapper = (props) => {
        return (
            <Component
            navigate={useNavigate()}
            {...props}
            />
        )
    }
    return Wrapper;
}