import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from '../Modules/Login';
import Home from '../Modules/Home';
import Data from '../Modules/Data';
import UserData from '../Modules/UserData';
import Charts from '../Modules/Charts';
import StationData from '../Modules/StationData';
import Background from '../Modules/Background';
import Organization from '../Modules/Organization';
import Contact from '../Modules/Contact';
import SirenLog from '../Modules/SirenLog';

class Router extends React.Component {
    render() {
        const Authentication = ({ children }) => {
            if (sessionStorage.getItem("status") === "true") {
                return children
            }
            else {
                return <Navigate to="/" />
            }
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Login />}></Route>
                    <Route exact path="/Home" element={
                        <Authentication>
                            <Home />
                        </Authentication>

                    }></Route>
                    <Route exact path="/Data" element={
                        <Authentication>
                            <Data />
                        </Authentication>
                    }></Route>
                    <Route exact path="/UserData" element={
                        <Authentication>
                            <UserData />
                        </Authentication>
                    }></Route>
                    <Route exact path="/Charts" element={
                        <Authentication>
                            <Charts />
                        </Authentication>
                    }></Route>
                    <Route exact path="/StationData" element={
                        <Authentication>
                            <StationData />
                        </Authentication>
                    }></Route>
                    <Route exact path="/Background" element={
                        <Authentication>
                            <Background />
                        </Authentication>
                    }></Route>
                    <Route exact path="/Organization" element={
                        <Authentication>
                            <Organization />
                        </Authentication>
                    }></Route>
                    <Route exact path="/Contact" element={
                        <Authentication>
                            <Contact />
                        </Authentication>
                    }></Route>
                    <Route exact path="/SirenLog" element={
                        <Authentication>
                            <SirenLog />
                        </Authentication>
                    }></Route>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default Router;
