import React from 'react';
import axios from 'axios';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar';
import Footer from '../SharedComponents/Footer';

import { BaseUrlConfig } from '../BaseUrlConfig';

class UserData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // data: [
            //     {
            //         "name": "test user 1",
            //         "role": "Normal"
            //     },
            //     {
            //         "name": "test advanced user 2",
            //         "role": "Advanced"
            //     },
            //     {
            //         "name": "Surechem",
            //         "role": "Admin"
            //     }
            // ],

            loading: false,
            data: [],
            username: "",
            password: "",
            userrole: "",

            current_user: ""
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.get(BaseUrlConfig() + '/user/get')
            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    renderRows = () => {
        let rows = []

        if (this.state.loading === true) {
            rows.push(
                <tr className="hover">
                    <td colSpan={6} style={{ textAlign: "center" }}>
                        <span className="loading loading-bars text-success loading-lg"></span>
                        <br />
                        <a href="#!" class="mb-4 text-base font-extrabold text-gray-900 md:text-base lg:text-xl">
                            <span class="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500
                        animate-pulse">
                                Sila tunggu, data sedang dimuatkan...</span>
                        </a>
                    </td>
                </tr>
            )
        }

        else {
            if (this.state.data.length === 0) {
                rows.push(
                    <tr className="hover">
                        <td colSpan={4} style={{ textAlign: "center" }}>Tiada Data.</td>
                    </tr>
                )
            }

            else {
                // this.state.data.forEach((row, idx) => {
                //     rows.push(
                //         <tr className="hover">
                //             <th>{idx + 1}</th>
                //             <td style={{ width: "30%" }}>{row["name"]}</td>
                //             <td style={{ width: "20%" }}>
                //                 {row["role"] === "Normal" ?
                //                     <span>Normal User</span> :
                //                     row["role"] === "Advanced" ?
                //                         <span>Advanced User</span> :
                //                         <span>Administrator</span>
                //                 }
                //             </td>
                //             <td style={{ width: "50%" }} className="flex gap-4">
                //                 <button className="btn btn-primary w-48" onClick={() => this.openEditFunction(idx)}>Edit</button>
                //                 <button className="btn btn-secondary w-48" onClick={() => this.deleteUser(idx)}>Delete</button>
                //             </td>
                //         </tr>
                //     )
                // })

                this.state.data.forEach((row, idx) => {
                    rows.push(
                        <tr className="hover" key={row["user_id"]}>
                            <th>{idx + 1}</th>
                            <td style={{ width: "30%" }}>{row["username"]}</td>
                            <td style={{ width: "20%" }}>
                                {row["userrole"] === "Admin" ?
                                    <span>Administrator</span> :
                                    row["userrole"] === "Advanced" ?
                                        <span>Advanced User</span> :
                                        <span>Normal User</span>
                                }
                            </td>
                            <td style={{ width: "50%" }} className="flex gap-4">
                                <button className="btn btn-primary w-48" onClick={() => this.openEditFunctionActual(idx, row["user_id"])}>Kemaskini</button>
                                <button className="btn btn-secondary w-48" onClick={() => this.deleteUserActual(row["user_id"])}>Padam</button>
                            </td>
                        </tr>
                    )
                })
            }
        }
        return rows
    }

    handleUserNameChanged = (event) => {
        this.setState({
            username: event.target.value
        })
    }

    handlePasswordChanged = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    handleUserRoleChanged = (event) => {
        this.setState({
            userrole: event.target.value
        })
    }

    openAddFunction = () => {
        this.setState({
            username: "",
            password: "",
            userrole: ""
        }, () => {
            window.add_user_modal.showModal()
        })
    }

    addUser = () => {
        this.setState({
            data: [...this.state.data,
            {
                "name": this.state.username,
                "role": this.state.userrole
            }
            ],
            username: "",
            password: "",
            userrole: ""
        })
    }

    addUserActual = () => {
        axios.post(BaseUrlConfig() + '/user/add', {
            "username": this.state.username,
            "password": this.state.password,
            "userrole": this.state.userrole
        })
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
                else if (response.data === "Failed") {
                    console.log("Username already exists.")
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    openEditFunction = (idx) => {
        this.setState({
            current_user: idx,
            username: this.state.data[idx]["name"],
            password: "",
            userrole: this.state.data[idx]["role"]
        }, () => {
            window.edit_user_modal.showModal()
        })
    }

    editUser = () => {
        let users = [...this.state.data]
        users[this.state.current_user] = {
            "name": this.state.username,
            "role": this.state.userrole
        }
        this.setState({
            data: users,
            username: "",
            password: "",
            userrole: "",
            current_user: ""
        })
    }

    openEditFunctionActual = (idx, user_id) => {
        this.setState({
            current_user: user_id,
            username: this.state.data[idx]["username"],
            password: "",
            userrole: this.state.data[idx]["userrole"]
        }, () => {
            window.edit_user_modal.showModal()
        })
    }

    editUserActual = () => {
        axios.post(BaseUrlConfig() + '/user/update', {
            "user_id": this.state.current_user,
            "username": this.state.username,
            "password": this.state.password,
            "userrole": this.state.userrole
        })
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
                else if (response.data === "Failed") {
                    console.log("Username already exists.")
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    deleteUser = (idx) => {
        let users = this.state.data
        users.splice(idx, 1)
        this.setState({ users })
    }

    deleteUserActual = (user_id) => {
        axios.post(BaseUrlConfig() + '/user/delete', {
            "user_id": user_id
        })
            .then(response => {
                if (response.data === "OK") {
                    this.init()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const mainContent = (
            <React.Fragment>
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-5xl">Senarai Pengguna</h1>
                </div>

                <div className="overflow-x-auto p-10 w-screen lg:w-full">
                    <table className="table border border-gray-300">
                        {/* head */}
                        <thead className="text-lg">
                            <tr>
                                <th></th>
                                <th>Nama</th>
                                <th>Peranan Pengguna</th>
                                <th>Tindakan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center">
                    <button className="btn btn-accent w-48" onClick={() => this.openAddFunction()}>Tambah Pengguna</button>
                </div>

                {/* Add user dialog */}
                <dialog id="add_user_modal" className="modal">
                    <form method="dialog" className="modal-box">
                        <h3 className="font-bold text-lg">Tambah Pengguna</h3>
                        <br />
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Nama</span>
                            </label>
                            <input type="text" placeholder="Sila taip nama pengguna di sini" className="input input-bordered"
                                value={this.state.username}
                                onChange={this.handleUserNameChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Kata Laluan</span>
                            </label>
                            <input type="password" placeholder="Sila taip kata laluan di sini" className="input input-bordered"
                                value={this.state.password}
                                onChange={this.handlePasswordChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Peranan Pengguna</span>
                            </label>
                            <select className="select select-sm select-bordered w-full"
                                value={this.state.userrole}
                                onChange={this.handleUserRoleChanged}>
                                <option disabled selected value="default">Sila Pilih Peranan Pengguna</option>
                                <option value="Normal">Normal User</option>
                                <option value="Advanced">Advanced User</option>
                                <option value="Admin">Administrator</option>
                            </select>
                        </div>

                        <br />
                        <div className="flex justify-center">
                            {/* <button className="btn btn-primary w-48" onClick={this.addUser}>Add User</button> */}
                            <button className="btn btn-primary w-48" onClick={this.addUserActual}>Tambah Pengguna</button>
                        </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>

                {/* Edit user dialog */}
                <dialog id="edit_user_modal" className="modal">
                    <form method="dialog" className="modal-box">
                        <h3 className="font-bold text-lg">Kemaskini Pengguna</h3>
                        <br />
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Nama</span>
                            </label>
                            <input type="text" placeholder="Sila taip nama pengguna di sini" className="input input-bordered"
                                value={this.state.username}
                                onChange={this.handleUserNameChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Kata Laluan</span>
                            </label>
                            <input type="password" placeholder="Sila taip kata laluan di sini" className="input input-bordered"
                                value={this.state.password}
                                onChange={this.handlePasswordChanged} />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Peranan Pengguna</span>
                            </label>
                            <select className="select select-sm select-bordered w-full"
                                value={this.state.userrole}
                                onChange={this.handleUserRoleChanged}>
                                <option disabled selected value="default">Sila Pilih Peranan Pengguna</option>
                                <option value="Normal">Normal User</option>
                                <option value="Advanced">Advanced User</option>
                                <option value="Admin">Administrator</option>
                            </select>
                        </div>

                        <br />
                        <div className="flex justify-center">
                            {/* <button className="btn btn-primary w-48" onClick={this.editUser}>Edit User</button> */}
                            <button className="btn btn-primary w-48" onClick={this.editUserActual}>Kemaskini</button>
                        </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                </dialog>

                <br />
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />

                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(UserData);