import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';
import OrganizationChart from '../static/Organization Chart.jpg'
import OrganizationChart2 from '../static/Organization Chart 2.jpg'

class Organization extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const mainContent = (
            <React.Fragment>
                <div className="flex justify-center text-center pt-5">
                    <h1 className="font-bold text-5xl">Struktur Organisasi</h1>
                </div>  

                <div className="flex justify-center p-5">
                    <img style={{ width: "60vw", height: "80vw"}} src={OrganizationChart} alt="#" />
                </div>

                <div className="flex justify-center p-5">
                    <img style={{ width: "60vw", height: "30vw"}} src={OrganizationChart2} alt="#" />
                </div>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />
                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(Organization);