import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import axios from 'axios';
import COAImage from '../static/Coat_of_arms_of_Malaysia.svg.png';
import JPSMLogo from '../static/logo-jpsm1.png';
import Logo from '../static/JMG Logo.png';
import SpecConnectLogo from '../static/Specconnect logo.png';

import { BaseUrlConfig } from '../BaseUrlConfig';

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",

      alert: false,
      loading: false,
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.enterListener)
    localStorage.clear()
    sessionStorage.clear()
  }
  
  enterListener = (event) => {
    if (event.code === "Enter") {
      this.submitHandler()
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterListener)
  }

  goHome = () => {
    this.props.navigate('/Home')
  }

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitHandler = () => {
    this.setState({ loading: true })
    axios.post(BaseUrlConfig() + "/auth/login",
      {
        "username": this.state.username,
        "password": this.state.password
      })
      .then(response => {
        if (response.data["response"] === "OK") {
          localStorage.setItem("user_identifier", response.data["user_id"])
          localStorage.setItem("user_tag", response.data["user_tag"])
          sessionStorage.setItem("status", true)
          this.goHome()
        }
        else {
          this.setState({
            alert: true,
            username: "",
            password: ""
          })
        }
        this.setState({ loading: false })
      })
      .catch(error => {
        this.setState({
          alert: true,
          username: "",
          password: ""
        })

        console.log(error)

        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <div className="flex h-screen px-4 md: p-0 justify-center items-center">
        <div className="card w-full max-w-xl bg-base-100 shadow-xl p-6 items-center border border-gray-300 m-auto">
          <div className="grid grid-cols-3">
            <img className="object-contain h-24 w-48" src={Logo} alt="#" />
            <img className="object-contain h-24 w-48" src={COAImage} alt="#" />
            <img className="object-contain h-24 w-48" src={JPSMLogo} alt="#" />
          </div>

          <div className="divider"></div>
          <img className="object-contain h-32 w-64" src={SpecConnectLogo} alt="#" />
          <h1 className="text-3xl font-extrabold text-gray-900 md:text-4xl lg:text-5xl text-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-500 from-sky-400">
              Sistem Pemantauan</span> dan</h1>
          <h1 className="mb-4 text-3xl font-extrabold text-gray-900 md:text-4xl lg:text-5xl text-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-500">
              Amaran Awal</span> Bencana Geologi</h1>
          <div className="card-body items-center text-center w-full space-y-2 -mt-8">
            <h2 className="card-title">Daftar Masuk</h2>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Nama Pengguna Anda</span>
              </label>
              <input type="text" placeholder="Taip nama pengguna di sini" className="input input-bordered"
                name="username" value={this.state.username} onChange={this.changeHandler} />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Kata Laluan Anda</span>
              </label>
              <input type="password" placeholder="********" className="input input-bordered"
                name="password" value={this.state.password} onChange={this.changeHandler} />
            </div>

            {/* <div className="card-actions w-full">
              <button className="btn btn-primary w-full" onClick={this.goHome}>Daftar Masuk</button>
            </div> */}

            <div className="card-actions w-full">
              <button className="btn btn-primary w-full" onClick={this.submitHandler}>
                {this.state.loading ?
                  <span className="loading loading-dots loading-lg"></span>
                  :
                  <span>Log Masuk</span>
                }
              </button>
            </div>
          </div>

          {this.state.alert ?
            <div className="alert alert-warning">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
              <span>Salah Nama pengguna atau kata laluan. Sila cuba lagi.</span>
            </div>
            :
            null
          }

        </div>
      </div>
    )
  }
}

export default withNavigate(Login);
