import React from 'react';
import { withNavigate } from '../Utilities/Navigation';
import NavSideBar from '../SharedComponents/NavSideBar'
import Footer from '../SharedComponents/Footer';

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const mainContent = (
            <React.Fragment>
                <div className="flex justify-center pt-5">
                    <h1 className="font-bold text-5xl">Hubungi Kami</h1>
                </div>

                <p className="px-5 lg:px-10 pt-10 text-3xl font-bold">
                    MAKLUMAT PERHUBUNGAN
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Jabatan Mineral Dan Geosains Malaysia Terengganu
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    PT3102K, Jalan Sultan Sulaiman,
                </p>

                <p className="px-5 lg:px-10 pt-1 text-justify">
                    20200 Kuala Terengganu, Terengganu
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Tel : 09-625 1000
                </p>

                <p className="px-5 lg:px-10 pt-10 text-justify">
                    Alamat: Jabatan Mineral dan Geosains, Aras 9, Menara PjH, No. 2, Jalan Tun Abdul Razak, Presint 2, 62100 PUTRAJAYA, MALAYSIA.
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Tel: (+603)21611033
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Faks: (+603)21611036
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Emel: info@jmg.gov.my
                </p>

                <p className="px-5 lg:px-10 pt-5 text-justify">
                    Website: www.jmg.gov.my
                </p>

                <br />
                <div className="divider"></div>
                <br />

                <p className="px-5 lg:px-10 text-3xl font-bold">
                    SENTIASA BERHUBUNG
                </p>

                <div className="pt-5 px-5 lg:px-10 pb-5">
                    <table style={{ width: '18%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '3%' }}>
                                    <a href="https://www.facebook.com/surechemmalaysia">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M22.5 0c.83 0 1.5.67 1.5 1.5v21c0 .83-.67 1.5-1.5 1.5h-6v-9h3l.75-3.75H16.5v-1.5c0-1.5.75-2.25 2.25-2.25h1.5V3.75h-3c-2.76 0-4.5 2.16-4.5 5.25v2.25h-3V15h3v9H1.5A1.5 1.5 0 0 1 0 22.5v-21C0 .67.67 0 1.5 0h21z" /></svg>
                                    </a>
                                </td>
                                <td style={{ width: '3%' }}>
                                    <a href="https://www.instagram.com/surechem1987">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z" /></svg>
                                    </a>
                                </td>
                                <td style={{ width: '3%' }}>
                                    <a href="https://twitter.com/SurechemMAS">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z" /></svg>
                                    </a>
                                </td>
                                <td style={{ width: '3%' }}>
                                    <a href="https://www.linkedin.com/company/surechem-sdn-bhd">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z" /></svg>
                                    </a>
                                </td>
                                <td style={{ width: '3%' }}>
                                    <a href="https://www.youtube.com/user/surechemmalaysia">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000"><path d="M12.04 3.5c.59 0 7.54.02 9.34.5a3.02 3.02 0 0 1 2.12 2.15C24 8.05 24 12 24 12v.04c0 .43-.03 4.03-.5 5.8A3.02 3.02 0 0 1 21.38 20c-1.76.48-8.45.5-9.3.51h-.17c-.85 0-7.54-.03-9.29-.5A3.02 3.02 0 0 1 .5 17.84c-.42-1.61-.49-4.7-.5-5.6v-.5c.01-.9.08-3.99.5-5.6a3.02 3.02 0 0 1 2.12-2.14c1.8-.49 8.75-.51 9.34-.51zM9.54 8.4v7.18L15.82 12 9.54 8.41z" /></svg>
                                    </a>
                                </td>
                                <td style={{ width: '3%' }}>
                                    <a href="https://www.surechem.com.my/contact-us">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <NavSideBar pageContent={mainContent} />
                <Footer />
            </React.Fragment>

        )
    }
}

export default withNavigate(Contact);